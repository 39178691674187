<template>
  <div class="news-list">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>系统消息</span>
      <span></span>
    </header>

    <main class="page-body">
      <ul class="list">
        <li v-if="list.length > 0">
          <section
            class="list-item flex-box"
            v-for="it in list"
            :key="it.PK_ID"
            @click="
              $router.push({
                name: 'MessDetail',
                query: { id: it.PK_ID },
              })
            "
          >
            <div class="thumbnail head-alert">
              {{ msgTypeDict[it.iType] }}
            </div>
            <div class="item-body">
              <div class="title f-16">{{ it.sTitle }}</div>
              <div class="brief">
                <span class="text-blue" v-if="it.bRead == false">
                  <font-awesome-icon icon="envelope" />
                  未读
                </span>

                <span v-else>
                  <font-awesome-icon icon="envelope-open" />已读
                </span>

                <span class="pull-right"
                  ><font-awesome-icon icon="clock" />
                  {{ it.dSendTime }}
                </span>
              </div>
            </div>
          </section>
        </li>
        <li v-else>
          <section class="padding no-item">
            <div class=""><img :src="emptyImg" /></div>
            <div class="nodesc">没有相关的信息</div>
          </section>
        </li>
      </ul>
    </main>
  </div>
</template>
<script>
import { queryMessageList } from '@/api/website.js';
import { BASE_URL } from '@/api/index.js';
import { formatDate } from '@/utils/index.js';
function getValidTime(value) {
  if (value == null || value == '') return '';
  var myDate = new Date(value.match(/\d+/)[0] * 1);
  return formatDate(myDate);
}
export default {
  data() {
    return {
      list: [],
      msgTypeDict: {
        1: '提醒',
        2: '通知',
        3: '警告',
      },

      emptyImg: require('../../assets/images/noinfo.png'),
      baseUrl: BASE_URL,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      queryMessageList(100).then(res => {
        if (res.Result == true) {
          console.log(res);
          this.list = res.DataDict['data'];
          this.list.forEach(it => {
            it.dSendTime = getValidTime(it.dSendTime);
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-body {
  padding: rem(40px);
  background: #fff;
}
.list-item {
  border-bottom: 1px solid #d4d3d3;
  padding: rem(20px) 0;
  width: 100%;
  height: 1.1rem;

  .head-alert {
    font-size: 0.4rem;
    background: #bdafd7;
    border-radius: 49%;
    line-height: 1.2rem;
    text-align: center;
    color: #fff;

    font-weight: bold;
  }
}
.title {
  font-size: rem(26px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.thumbnail {
  width: rem(120px);
  height: rem(120px);
  margin-right: rem(40px);
  position: relative;
  float: left;
  img {
    border-radius: rem(10px);
    width: rem(120px);
    height: rem(110px);
    border: 1px solid #b8b8b8;
  }
}

.brief {
  font-size: 12px;
  margin-top: rem(30px);
  color: #b8b8b8;
}

.no-item {
  text-align: center;
  margin: rem(40px);
  img {
    height: rem(149px);
  }
  .no-desc {
    font-size: rem(13px);
    text-align: center;
  }
}
</style>
